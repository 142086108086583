import React from 'react';
import Layout from "../components/layout";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    boxContenedor:{
        position:'relative',
        textAlign:"center",
        margin:"2rem",
    },
    titulo: {
        fontSize: '2.5rem',
        marginBottom: '2rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
        },
    },
    boxTexto: {
        marginBottom: '2rem'
    },
    texto: {
        marginLeft:'25%',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            marginLeft:'0',
            width: '100%'
        },
    },
    iframe: {
        width:"50%",
        height:"1100px",
        [theme.breakpoints.down('sm')]: {
            height:"1230px",
            width: '100%'
        },
    },
}));

function Contacto(props) {
    const classes = useStyles();
    return (
        <Layout>
            <Box className={classes.boxContenedor} >
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe5huFcKH_OKW4lQQt5In7LtUNiLk25dmKYfBAqFr-CNqG_Yg/viewform?embedded=true" className={classes.iframe} frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
            </Box>
        </Layout>
    );
}

export default Contacto;